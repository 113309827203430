import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Heart, User } from 'react-feather';
import ShoppingBagIcon from './ShoppingBagIcon';
import Modal from './Modal';
import './Navbar.css';
import logo from './assets/logo black.png';

const Navbar = ({ cart, isAuthenticated }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [showUserTooltip, setShowUserTooltip] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleMenuItemClick = () => {
    setMenuOpen(false); // Close the menu when a menu item is clicked
  };

  const handleUserIconHover = () => {
    if (isAuthenticated) {
      setShowUserTooltip(true);
    }
  };

  const handleUserIconLeave = () => {
    setShowUserTooltip(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <header className="header">
      <div className="logo">
        <img src={logo} alt="Logo" style={{ width: '60px' }} />
      </div>
      <nav className={`menu ${menuOpen ? 'open' : ''}`}>
        <ul>
          <li><NavLink exact to="/" activeClassName="active" onClick={handleMenuItemClick}>Home</NavLink></li>
          <li><NavLink to="/men" activeClassName="active" onClick={handleMenuItemClick}>Men</NavLink></li>
          <li><NavLink to="/women" activeClassName="active" onClick={handleMenuItemClick}>Women</NavLink></li>
          <li><NavLink to="/sneakers" activeClassName="active" onClick={handleMenuItemClick}>Sneakers</NavLink></li>
          <li><NavLink to="/shoecare" activeClassName="active" onClick={handleMenuItemClick}>ShoeCare</NavLink></li>
          <li><NavLink to="/contact" activeClassName="active" onClick={handleMenuItemClick}>Contact</NavLink></li>
        </ul>
        <div className="icons">
          <ShoppingBagIcon cart={cart} className="icon" />
          <Heart className="icon-heart" />
          <div
            className="user-icon-wrapper"
            onMouseEnter={handleUserIconHover}
            onMouseLeave={handleUserIconLeave}
            onClick={openModal}
          >
            <User className="icon" />
            {showUserTooltip && (
              <div className="user-tooltip">
                <p>My Account</p>
                <p>My Orders</p>
                {/* Add other user-related options */}
              </div>
            )}
          </div>
        </div>
      </nav>
      <div className={`burger ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <div className="line1"></div>
        <div className="line2"></div>
        <div className="line3"></div>
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal} />
    </header>
  );
};

export default Navbar;

import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './Category.css';

const Category = () => {
  return (
    <div className='categories'>
      <div className='boxes1'>
        <div className="text-container">
          <h1 className='cat-text'>
            <Link to='/men' className='link-color'>MEN</Link> {/* Use Link with the 'to' prop */}
          </h1>
        </div>
      </div>

      <div className='boxes2'>
        <div className="text-container">
          <h1 className='cat-text'>
            <Link to='/women' className='link-color'>WOMEN</Link> {/* Use Link for navigation */}
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Category;

import React from 'react';
import PaystackPop from '@paystack/inline-js';
import './PaystackIntegration.css';

const PaystackIntegration = ({ amount, email, onSuccess, onCancel }) => {
  const handlePaystackPayment = () => {
    if (!email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      alert('Please enter a valid email address');
      return;
    }

    try {
      const paystack = new PaystackPop();

      paystack.newTransaction({
        key: 'pk_test_3b2ab78c1eeff8188b19dea54eb6dfab8eb964ea',
        email: email,
        amount: amount * 100, // Converts ₦222,500 to 22,250,000 kobo
        onSuccess: (transaction) => {
          alert(`Payment Successful! Reference: ${transaction.reference}`);
          if (onSuccess) onSuccess(transaction);
        },
        onCancel: () => {
          alert('Payment Cancelled');
          if (onCancel) onCancel();
        }
      });
    } catch (error) {
      console.error("Paystack payment error:", error);
      alert('An error occurred while processing the payment. Please try again.');
    }
  };

  return (
    <div>
      {/* Display formatted amount (e.g., ₦222,500.00) */}
      <p className="total-amount">
        Total Amount: ₦{(amount).toLocaleString('en-NG', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}
      </p>

      <button onClick={handlePaystackPayment} className="pay-button">
        Pay Now
      </button>
    </div>
  );
};

export default PaystackIntegration;
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCart } from './CartContext';
import { FiShoppingBag } from 'react-icons/fi';
import './ShoppingBagIcon.css';

const ShoppingBagIcon = () => {
  const { cartItems = [], removeFromCart, updateCartItems } = useCart();
  const [isBagOpen, setIsBagOpen] = useState(false);
  const navigate = useNavigate();




  // Calculate total items and total price
  const totalItems = cartItems.reduce((total, item) => total + item.quantity, 0);
  const totalPrice = cartItems.reduce((total, item) => total + item.price * item.quantity, 0);

const handlePlaceOrder = () => {
  setIsBagOpen(false);  // This closes the shopping bag
  navigate('/login', { state: { cartItems } });
};

  // Handle deleting an item from the cart
  const handleDeleteItem = (id) => {
    removeFromCart(id);
  };

  // Handle changing the quantity of an item
  const handleQuantityChange = (id, newQuantity, event) => {
    event.stopPropagation();
    if (newQuantity < 1) return; // Prevent quantity from being less than 1
    const updatedCartItems = cartItems.map((item) =>
      item.id === id ? { ...item, quantity: newQuantity } : item
    );
    updateCartItems(updatedCartItems);
  };

  // Toggle the shopping bag visibility
  const toggleBag = () => {
    setIsBagOpen(!isBagOpen);
  };

  return (
    <div className="shopping-bag-icon">
      {/* Shopping bag icon with item count */}
      <span className="bag-icon" onClick={toggleBag}>
        <FiShoppingBag />
        {totalItems > 0 && <span className="item-count">{totalItems}</span>}
      </span>

      {/* Shopping bag overlay and content */}
      {isBagOpen && (
        <>
          <div className="overlayed" onClick={toggleBag}></div>
          <div className="custom-tooltip">
            {/* Header with close button */}
            <div className="header-bag">
              <h2 className="crt">MY BAG</h2>
              <div className="close-icon" onClick={toggleBag}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M10.586 12L2.793 4.207l1.414-1.414L12 10.586l7.793-7.793l1.414 1.414L13.414 12l7.793 7.793l-1.414 1.414L12 13.414l-7.793 7.793l-1.414-1.414z"
                  />
                </svg>
              </div>
            </div>

            {/* Display cart items or empty message */}
            {cartItems.length === 0 ? (
              <p className="items">You have no items in your bag.</p>
            ) : (
              <>
                {/* Map through cart items */}
                {cartItems.map((item) => (

                  
                  <div key={item.id} className="cart-item">
                      <div className="item-details-bag">
                      <p className="item-name">{item.name}</p>
                      <p className="item-price">₦{item.price.toFixed(2)}</p>
                      <p className="item-color">Color: {item.color}</p>
                      <p className="item-size">Size: {item.size}</p>
                      <div className="quantity-display">
                        <p>Qty:</p>
                        <button onClick={(e) => handleQuantityChange(item.id, item.quantity - 1, e)}>
                          -
                        </button>
                        <p className="item-quantity">{item.quantity}</p>
                        <button onClick={(e) => handleQuantityChange(item.id, item.quantity + 1, e)}>
                          +
                        </button>
                      </div>
                    </div>
                  
                    <div className="lefty-bag">
                      <button
                        className="delete-item-button"
                        onClick={() => handleDeleteItem(item.id)}
                      >
                        X
                      </button>
                      <img src={item.image} alt={item.name} className="item-image" />
                    </div>
                  </div>
                ))}

                {/* Summary section */}
                <div className="summary">
                  <p className="total-items">
                    {totalItems} item{totalItems !== 1 ? 's' : ''}
                  </p>
                  <p className="total-price">Subtotal: ₦{totalPrice.toFixed(2)}</p>
                </div>

                {/* Checkout button */}
                <div className="bt-grp">
                  <button className="btn1" onClick={handlePlaceOrder}>
                    CHECKOUT
                  </button>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ShoppingBagIcon;
import React, { useState } from 'react';
import { useCart } from './CartContext';
import { FiPackage, FiTruck, FiCreditCard, FiCheckCircle, FiHome } from 'react-icons/fi';
import './Account.css';
import PaystackIntegration from './Paystackintegration';

const Account = () => {
  const [activeSection, setActiveSection] = useState('Orders');
  const { cartItems = [], clearCart } = useCart();
  const [orderDetails, setOrderDetails] = useState(null);
  
  const [formData, setFormData] = useState({
    FullName: '',
    StreetAddress: '',
    City: 'Lagos',
    State: '',
    Phone: '',
    Email: '',
    Country: 'Nigeria',
    ShippingArea: ''
  });

  const subtotal = cartItems.reduce(
    (total, product) => total + product.price * product.quantity,
    0
  );

  const getShippingCost = () => {
    if (!formData.ShippingArea) return 0;
    const priceString = formData.ShippingArea.split('₦')[1]?.replace(/,/g, '') || '0';
    return parseInt(priceString);
  };

  const total = subtotal + getShippingCost();
  const formatPrice = (price) => Math.round(price).toLocaleString();

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData(prev => ({ ...prev, [id]: value }));
  };

  const isShippingFormValid = () => (
    formData.FullName &&
    formData.StreetAddress &&
    formData.State &&
    formData.Phone &&
    formData.Email &&
    formData.ShippingArea
  );

  const handlePaymentSuccess = (reference) => {
    const confirmationNumber = `ORD-${Date.now()}`;
    setOrderDetails({
      confirmationNumber,
      items: [...cartItems],
      subtotal,
      shippingCost: getShippingCost(),
      total,
      shippingInfo: formData
    });
    clearCart();
    setActiveSection('OrderConfirm');
  };

  const renderContent = () => {
    switch (activeSection) {
      case 'Orders':
        return (
          <div className="order-section">
            <div className="section-header">
              <FiPackage className="section-icon" />
              <h2 className="cart-text-adjust">Your Cart ({cartItems.length})</h2>
            </div>
            
            {cartItems.length === 0 ? (
              <div className="empty-state">
                <FiPackage size={48} className="empty-icon" />
                <p>Your cart is empty</p>
              </div>
            ) : (
              <div className="joint-column">
                <div className="product-list">
                  {cartItems.map((product) => (
                    <div key={product.id} className="product-card">
                      <img 
                        src={`/assets/products/${product.image}`} 
                        alt={product.name} 
                        className="product-image"
                      />
                      <div className="product-info">
                        <h3 className="product-name">{product.name}</h3>
                        <div className="product-meta">
                          <span className="product-sku">{product.sku}</span>
                          <span className="product-size">Size: {product.size}</span>
                          <span className="product-color">Color: {product.color}</span>
                        </div>
                        <div className="price-quantity">
                          <span className="product-price">₦{formatPrice(product.price)}</span>
                          <span className="product-quantity">Qty: {product.quantity}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
      
                {cartItems.length > 0 && (
                  <div className="order-summary-card">
                    <h3 className="summary-title">Order Summary</h3>
                    <div className="summary-details">
                      <div className="price-row">
                        <span>Subtotal</span>
                        <span>₦{formatPrice(subtotal)}</span>
                      </div>
                      <div className="price-row">
                        <span>Shipping</span>
                        <span>Select area</span>
                      </div>
                      <div className="price-row total">
                        <span>Total</span>
                        <span className="total-price">₦{formatPrice(subtotal)}</span>
                      </div>
                    </div>
                    <button 
                      className="proceed-btn"
                      onClick={() => setActiveSection('Shipping')}
                    >
                      Continue to Shipping
                      <FiTruck className="btn-icon" />
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        );

      case 'Shipping':
        return (
          <div className="shipping-section">
            <div className="section-header">
              <FiTruck className="section-icon" />
              <h2>Shipping Information</h2>
            </div>
      
            <div className="shipping-section-inner">
              <div className="shipping-content">
                <div className="form-and-prices">
                  <form className="shipping-form">
                    {Object.entries(formData).map(([key, value]) => (
                      key !== 'City' && key !== 'Country' && key !== 'ShippingArea' && (
                        <div className="form-group" key={key}>
                          <label htmlFor={key}>
                            {key.replace(/([A-Z])/g, ' $1').trim()}
                          </label>
                          <input
                            type={key === 'Email' ? 'email' : 'text'}
                            id={key}
                            value={value}
                            onChange={handleInputChange}
                            className="form-input"
                            required
                          />
                        </div>
                      )
                    ))}
                    
                    <div className="form-group">
                      <label>Delivery Area</label>
                      <select
                        id="ShippingArea"
                        value={formData.ShippingArea}
                        onChange={handleInputChange}
                        className="form-input"
                        required
                      >
                        <option value="">Select your area</option>
                        <optgroup label="Island Areas">
                          <option value="Marina, Lagos Island, Obalende - ₦3,000">Marina, Lagos Island, Obalende - ₦3,000</option>
                          <option value="Ikoyi, VI - ₦3,000">Ikoyi, VI - ₦3,000</option>
                          <option value="Lekki Phase 1 - ₦3,500">Lekki Phase 1 - ₦3,500</option>
                          <option value="Osapa London, Ikate Lekki, Agungi, Marwa Lekki - ₦4,000">Osapa London, Ikate Lekki, Agungi, Marwa Lekki - ₦4,000</option>
                          <option value="Chevron, Alpha Beach Lekki - ₦4,500">Chevron, Alpha Beach Lekki - ₦4,500</option>
                          <option value="VGC, Ikota Lekki - ₦6,000">VGC, Ikota Lekki - ₦6,000</option>
                          <option value="Ajah, Badore, Langbasa, Addo Road, Ogombo, Abraham Adesanya - ₦7,000">Ajah, Badore, Langbasa, Addo Road, Ogombo, Abraham Adesanya - ₦7,000</option>
                          <option value="Sangotedo - ₦10,000">Sangotedo - ₦10,000</option>
                        </optgroup>
                        <optgroup label="Mainland Areas">
                          <option value="Yaba, Ebute Metta - ₦2,000">Yaba, Ebute Metta - ₦2,000</option>
                          <option value="Akoka, Mushin, Ilupeju, Palm Grove, Obanikoro - ₦2,000">Akoka, Mushin, Ilupeju, Palm Grove, Obanikoro - ₦2,000</option>
                          <option value="Surulere, Anthony, Bariga, Maryland, Somolu - ₦2,500">Surulere, Anthony, Bariga, Maryland, Somolu - ₦2,500</option>
                          <option value="Ikeja, Oshodi, Ojota, Ogudu, Gbagada, Mafoluku, Ajao, Oworo - ₦3,000">Ikeja, Oshodi, Ojota, Ogudu, Gbagada, Mafoluku, Ajao, Oworo - ₦3,000</option>
                          <option value="Ketu, Magodo 1 & 2, Omole 1&2, Ogba, Isolo, Alapere - ₦4,000">Ketu, Magodo 1 & 2, Omole 1&2, Ogba, Isolo, Alapere - ₦4,000</option>
                          <option value="Okota, Amuwo, Festac, Apapa, Ojodu - ₦5,000">Okota, Amuwo, Festac, Apapa, Ojodu - ₦5,000</option>
                          <option value="Agege, Akowonjo, Dopemu - ₦4,000">Agege, Akowonjo, Dopemu - ₦4,000</option>
                          <option value="Egbeda - ₦4,500">Egbeda - ₦4,500</option>
                          <option value="Ikotun, Ejigbo, Iju, Idimu, Igando - ₦6,000">Ikotun, Ejigbo, Iju, Idimu, Igando - ₦6,000</option>
                          <option value="Abule Egba, Iyana Ipaja - ₦7,000">Abule Egba, Iyana Ipaja - ₦7,000</option>
                        </optgroup>
                      </select>
                    </div>
                  </form>
      
                  <div className="order-summary-card">
                    <h3 className="summary-title">Order Summary</h3>
                    <div className="summary-details">
                      <div className="price-row">
                        <span>Subtotal</span>
                        <span>₦{formatPrice(subtotal)}</span>
                      </div>
                      <div className="price-row">
                        <span>Shipping</span>
                        <span>
                          {formData.ShippingArea ? 
                            `₦${formatPrice(getShippingCost())}` : 
                            'Select area'
                          }
                        </span>
                      </div>
                      <div className="price-row total">
                        <span>Total</span>
                        <span className="total-price">
                          ₦{formatPrice(total)}
                        </span>
                      </div>
                    </div>
                    <button
                      className="proceed-btn"
                      onClick={() => isShippingFormValid() && setActiveSection('Payment')}
                      disabled={!isShippingFormValid()}
                    >
                      Continue to Payment
                      <FiCreditCard className="btn-icon" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );

        case 'Payment':
          return (
            <div className="payment-section">
              <div className="section-header">
                <FiCreditCard className="section-icon" />
                <h2>Payment Details</h2>
              </div>
              
              <div className="payment-content">
              
                
                <PaystackIntegration
                  amount={total}
                  email={formData.Email}
                  fullName={formData.FullName}
                  onSuccess={handlePaymentSuccess}
                  onCancel={() => console.log('Payment Cancelled')}
                />
              </div>
            </div>
          );


      case 'OrderConfirm':
        return (
          <div className="confirmation-section">
            <div className="confirmation-header">
              <FiCheckCircle className="section-icon success" />
              <h2>Order Confirmed!</h2>
              <p className="confirmation-number">Order ID: {orderDetails?.confirmationNumber}</p>
            </div>
            
            <div className="order-details">
              <h3>Order Summary</h3>
              <div className="product-list">
                {orderDetails?.items.map((product) => (
                  <div key={product.id} className="product-card">
                    <img 
                      src={`/assets/products/${product.image}`} 
                      alt={product.name} 
                      className="product-image"
                    />
                    <div className="product-info">
                      <h3 className="product-name">{product.name}</h3>
                      <div className="product-meta">
                        <span className="product-sku">{product.sku}</span>
                        <span className="product-size">Size: {product.size}</span>
                        <span className="product-color">Color: {product.color}</span>
                      </div>
                      <div className="price-quantity">
                        <span className="product-price">₦{formatPrice(product.price)}</span>
                        <span className="product-quantity">Qty: {product.quantity}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              
              <div className="order-summary-card">
                <h3 className="summary-title">Total Summary</h3>
                <div className="summary-details">
                  <div className="price-row">
                    <span>Subtotal</span>
                    <span>₦{formatPrice(orderDetails?.subtotal)}</span>
                  </div>
                  <div className="price-row">
                    <span>Shipping</span>
                    <span>₦{formatPrice(orderDetails?.shippingCost)}</span>
                  </div>
                  <div className="price-row total">
                    <span>Total Paid</span>
                    <span className="total-price">₦{formatPrice(orderDetails?.total)}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );

      default:
        return (
          <div className="dashboard-section">
            <div className="section-header">
              <FiHome className="section-icon" />
              <h2>Account Dashboard</h2>
            </div>
            <p className="dashboard-welcome">Welcome back! Manage your orders and account settings here.</p>
          </div>
        );
    }
  };

  return (
    <div className="account-container">
      <nav className="account-sidebar">
        {['Dashboard', 'Orders', 'Shipping', 'Payment'].map((section) => (
          <button
            key={section}
            className={`nav-btn ${activeSection === section ? 'active' : ''}`}
            onClick={() => setActiveSection(section)}
          >
            {section === 'Dashboard' && <FiHome className="nav-icon" />}
            {section === 'Orders' && <FiPackage className="nav-icon" />}
            {section === 'Shipping' && <FiTruck className="nav-icon" />}
            {section === 'Payment' && <FiCreditCard className="nav-icon" />}
            {section}
          </button>
        ))}
      </nav>

      <main className="account-content">
        {renderContent()}
      </main>
    </div>
  );
};

export default Account;
import React, { useState, useEffect } from 'react';
import Carousel from 'react-elastic-carousel';
import ProductModal from './ProductModal';
import './ProductsSection.css';

import productsOne from './assets/products/product1.png';
import productsTwo from './assets/products/product2.png';
import productsThree from './assets/products/product3.png';
import productsFour from './assets/products/products4.png';
import productsFive from './assets/products/products5.png';
import productsSix from './assets/products/product6.png';

const ProductsSection = () => {
  const productsData = [
    { id: 1, name: "White Sole Sneakers", price: 55000, image: productsOne, description: "Comfortable white sneakers." },
    { id: 2, name: "Brown leather double-buckle", price: 65000, image: productsTwo, description: "Stylish leather shoes." },
    { id: 3, name: "Dab-brown double-buckle", price: 59500, image: productsThree, description: "Elegant double-buckle shoes." },
    { id: 4, name: "Plain leather shoe black", price: 37000, image: productsFour, description: "Classic black shoes." },
    { id: 5, name: "Brown leather tassel sneakers", price: 65000, image: productsFive, description: "Tassel-designed sneakers." },
    { id: 6, name: "Brown Leather Chelsea Boot", price: 12990, image: productsSix, description: "Stylish Chelsea boots." },
  ];

  const [itemsToShow, setItemsToShow] = useState(4);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const updateItemsToShow = () => {
    setItemsToShow(window.innerWidth >= 768 ? 4 : 1);
  };

  useEffect(() => {
    updateItemsToShow();
    window.addEventListener('resize', updateItemsToShow);
    return () => window.removeEventListener('resize', updateItemsToShow);
  }, []);

  const openModal = (product) => {
    setSelectedProduct(product);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedProduct(null);
    setIsModalOpen(false);
  };

  return (
    <div className="products-section">
      <Carousel showArrows={true} pagination={false} itemsToShow={itemsToShow} className="product-slider">
        {productsData.map((product) => (
          <div key={product.id} className="product-link" onClick={() => openModal(product)}>
            <img src={product.image} alt={product.name} style={{ width: '270px', height: '180px', cursor: 'pointer' }} />
            <h3 className="product-title">{product.name}</h3>
            <p className="product-price">₦{product.price.toLocaleString()}</p>
          </div>
        ))}
      </Carousel>

      {/* Product Modal */}
      {selectedProduct && (
        <ProductModal product={selectedProduct} isOpen={isModalOpen} onClose={closeModal} />
      )}
    </div>
  );
};

export default ProductsSection;

import React, { useState } from 'react';
import './Sneakers.css';
import Footer from '../components/Footer';
import Bottomft from '../components/Bottomft';
import ProductModal from './ProductModal';

import sneaker1 from './assets/products/product2.png';
import sneaker2 from './assets/products/product1.png';
import sneaker3 from './assets/products/sneaker.jpeg';
import sneaker4 from './assets/products/products5.png';
import sneaker5 from './assets/products/brown.png';
import sneaker6 from './assets/products/nubuck.png';

const Sneakers = () => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    setIsModalOpen(true);
  };

  const sneakersData = [
    {
      id: 1,
      category: 'Sneakers',
      name: 'Monk lace Sneakers',
      price: 102500,
      image: sneaker1,
      description: 'The MonkLace Sneakers offer a perfect blend of sophistication and versatility...'
    },
    {
      id: 2,
      category: 'Sneakers',
      name: 'Penny Sneakers PENNOVA',
      price: 99500,
      image: sneaker2,
      description: 'Simple yet effective, these stylish PENNOVA sneakers are crafted...'
    },
    {
      id: 3,
      category: 'Sneakers',
      name: 'Belgian Sneakers MARINER',
      price: 99500,
      image: sneaker3,
      description: 'Elevate your wardrobe with the Belgian Penny Sneakers...'
    },
    {
      id: 4,
      category: 'Sneakers',
      name: 'Patina Monk Sneakers',
      price: 110000,
      image: sneaker4,
      description: 'Step into refined elegance with the Patina Monk Sneakers...'
    },
    {
      id: 5,
      category: 'Sneakers',
      name: 'Brogue Sneakers TREAD',
      price: 105000,
      image: sneaker5,
      description: 'Elevate your sneaker game with the Brogue Sneakers...'
    },
    {
      id: 6,
      category: 'Sneakers',
      name: 'Nubuck Luxe Sneakers',
      price: 112000,
      image: sneaker6,
      description: 'Step into a world of style and comfort with the Nubuck Sneakers...'
    }
  ];

  return (
    <>
      <div className="sneakers-container">
        {sneakersData.map(sneaker => (
          <div 
            key={sneaker.id} 
            className="sneaker-card"
            onClick={() => handleProductClick(sneaker)}
          >
            <img src={sneaker.image} alt={sneaker.name} />
            <h3>{sneaker.name}</h3>
            <p>₦{sneaker.price.toLocaleString()}</p>
          </div>
        ))}
      </div>
      
      <ProductModal
        product={selectedProduct}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
      <Footer />
      <Bottomft />
    </>
  );
};

export default Sneakers;
import React, { useState } from 'react';
import './Men.css';
import ProductModal from './ProductModal'; // Update the path
import Footer from '../components/Footer';
import Bottomft from '../components/Bottomft';
import sneaker1 from './assets/products/product2.png';
import sneaker2 from './assets/products/product1.png';
import sneaker3 from './assets/products/sneaker.jpeg';
import sneaker4 from './assets/products/shoedoublebuck.png';
import sneaker5 from './assets/products/brown.png';
import sneaker6 from './assets/products/nubuck.png';
import boot1 from './assets/products/boot1-01.png';
import boot2 from './assets/products/boot2-01.png';
import boot3 from './assets/products/boot3-01.png';
import boot4 from './assets/products/boot4-01.png';
import shoes1 from './assets/products/shoe2.png';
import shoes2 from './assets/products/shoe3.png';

const Men = () => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [cart, setCart] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('All');

  const handleFilterClick = (filter) => {
    setSelectedFilter(filter);
  };

  const products = [
    { id: 1, category: 'Sneakers', name: 'Monk lace', price: 102500, image: sneaker1 },
    { id: 2, category: 'Sneakers', name: 'Penny PENNOVA', price: 99500, image: sneaker2 },
    { id: 3, category: 'Sneakers', name: 'Belgian MARINER', price: 99500, image: sneaker3 },
    { id: 4, category: 'Sneakers', name: 'Patina Monk', price: 110000, image: sneaker4 },
    { id: 5, category: 'Sneakers', name: 'Brogue TREAD', price: 105000, image: sneaker5 },
    { id: 6, category: 'Sneakers', name: 'Nubuck Luxe', price: 112000, image: sneaker6 },
    { id: 7, category: 'Boots', name: 'Chelsea OLIVE', price: 139000, image: boot1 },
    { id: 8, category: 'Boots', name: 'Multi-Panel STEELE', price: 150000, image: boot2 },
    { id: 9, category: 'Boots', name: 'Chelsea HUMM', price: 139000, image: boot3 },
    { id: 10, category: 'Boots', name: 'Jodhpur NOOR', price: 149500, image: boot4 },
    { id: 11, category: 'Shoes', name: 'Monk FLEX', price: 99500, image: shoes1 },
    { id: 12, category: 'Shoes', name: 'Oxford DENBY', price: 110000, image: shoes2 },
  ];

  const filteredProducts = selectedFilter === 'All' ? products : products.filter(product => product.category === selectedFilter);

  return (
    <>
      <section className="men-section">
        <div className="sidebar-panel">
          <ul>
            <li className={selectedFilter === 'All' ? 'active' : ''} onClick={() => handleFilterClick('All')}>All Shoes</li>
            <li className={selectedFilter === 'Sneakers' ? 'active' : ''} onClick={() => handleFilterClick('Sneakers')}>SNEAKERS</li>
            <li className={selectedFilter === 'Boots' ? 'active' : ''} onClick={() => handleFilterClick('Boots')}>BOOTS</li>
            <li className={selectedFilter === 'Shoes' ? 'active' : ''} onClick={() => handleFilterClick('Shoes')}>SHOES</li>
          </ul>
        </div>
        <div className="product-list-items">
          {filteredProducts.map((product) => (
            <div className="product-item" key={product.id}>
              <img 
                src={product.image} 
                alt={product.name} 
                onClick={() => setSelectedProduct(product)} 
                className="clickable-image"
              />
              <h3>{product.name}</h3>
              <p className="product-price">₦{product.price}</p>
            </div>
          ))}
        </div>
      </section>

      <ProductModal
        product={selectedProduct}
        isOpen={selectedProduct !== null}
        onClose={() => setSelectedProduct(null)}
        onAddToCart={(newItem) => {
          setCart([...cart, newItem]);
          setSelectedProduct(null);
        }}
      />

      <Footer />
      <Bottomft />
    </>
  );
};

export default Men;

import React from 'react';

const OrderConfirm = ({ orderDetails }) => {
  const formatPrice = (price) => Math.round(price);

  if (!orderDetails) {
    return <div className="order-confirm-section"><p>Loading order details...</p></div>;
  }

  return (
    <div className="order-confirm-section">
      <h2 className="thank-you-message">Thank you for your order! 🎉</h2>
      <div className="confirmation-details">
        <p className="confirmation-number">
          Order Confirmation Number: <strong>{orderDetails.confirmationNumber}</strong>
        </p>
        
        <h3>Order Summary:</h3>
        <table className="order-summary-table">
          <thead>
            <tr>
              <th>Product</th>
              <th>Price</th>
              <th>Quantity</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {orderDetails.items.map((item) => (
              <tr key={item.id}>
                <td className="product-info">
                  <img 
                    src={`../assets/products/${item.image}`} 
                    alt={item.name} 
                    className="product-image"
                  />
                  <div>
                    <p className="product-name">{item.name}</p>
                    <p className="product-attributes">
                      Size: {item.size} | Color: {item.color}
                    </p>
                  </div>
                </td>
                <td>₦{formatPrice(item.price)}</td>
                <td>{item.quantity}</td>
                <td>₦{formatPrice(item.price * item.quantity)}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="order-totals">
          <p>Subtotal: ₦{formatPrice(orderDetails.subtotal)}</p>
          <p>Shipping: ₦{formatPrice(orderDetails.shippingCost)}</p>
          <p className="total">Grand Total: ₦{formatPrice(orderDetails.total)}</p>
        </div>

        <h3>Shipping Information:</h3>
        <div className="shipping-info">
          <p>Name: {orderDetails.shippingInfo.fullName}</p>
          <p>Address: {orderDetails.shippingInfo.streetAddress}</p>
          <p>City: {orderDetails.shippingInfo.city}</p>
          <p>State: {orderDetails.shippingInfo.state}</p>
          <p>Email: {orderDetails.shippingInfo.email}</p>
          <p>Phone: {orderDetails.shippingInfo.phone}</p>
        </div>

        <p className="closing-message">
          Your order is being processed. You'll receive a confirmation email shortly.
          Thank you for shopping with us!
        </p>
      </div>
    </div>
  );
};

export default OrderConfirm;
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import './ProductModal.css';
import { useCart } from './CartContext';
import { Icon } from '@iconify/react';

const ProductModal = ({ product, isOpen, onClose }) => {
  const [selectedSize, setSelectedSize] = useState('');
  const [selectedColor, setSelectedColor] = useState('');
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const [totalPrice, setTotalPrice] = useState(product ? product.price : 0);
  const [showValidationMessage, setShowValidationMessage] = useState(false);
  const { addToCart } = useCart();

  useEffect(() => {
    if (product) {
      setTotalPrice(product.price * selectedQuantity);
    }
  }, [product, selectedQuantity]);

  const handleColorChange = (color) => {
    setSelectedColor(color);
    setShowValidationMessage(false);
  };

  const handleSizeSelect = (size) => {
    setSelectedSize(size);
    setShowValidationMessage(false);
  };

  const handleAddToCart = () => {
    if (selectedSize && selectedColor && selectedQuantity > 0) {
      const item = {
        id: product.id,
        name: product.name,
        image: product.image,
        color: selectedColor,
        size: selectedSize,
        quantity: selectedQuantity,
        price: product.price,
      };

      addToCart(item);
      onClose();
    } else {
      setShowValidationMessage(true);
    }
  };

  if (!product) return null;

  const formattedTotalPrice = new Intl.NumberFormat('en-NG', {
    style: 'currency',
    currency: 'NGN',
  }).format(totalPrice);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Product Modal"
      aria-modal="true"
      className="Modal-container"
    >
      <div className="modal-content">
        <button className="close-button" onClick={onClose} aria-label="Close">
          <Icon icon="mdi:close" />
        </button>

        {showValidationMessage && (
          <div className="validation-message-top">
            ⚠️ Please select size and color before adding to cart.
          </div>
        )}

        <div className="modal-layout">
          <div className="product-description">
            <h2 className='product-title'>{product.name}</h2>
            <img src={product.image} alt={product.name} className='product-image-sum' />
            <div className="product-para-text">
              {product.description || `Experience perfect balance between style and functionality with these premium shoes. Featuring advanced cushioning technology and breathable materials, they're ideal for both casual wear and physical activities.`}
            </div>
          </div>

          <div className="product-details">
            <div className="color-group">
              <label className='color-style'>Color:</label>
              <div className="color-options">
                {['red', 'blue', 'green', 'black', 'grey', 'brown', 'wine'].map((color) => (
                  <button
                    key={color}
                    className={`color-option ${selectedColor === color ? 'selected' : ''}`}
                    style={{ backgroundColor: color }}
                    onClick={() => handleColorChange(color)}
                  ></button>
                ))}
              </div>
            </div>

            <div className="quantity-group">
              <label className='qty'>Quantity:</label>
              <div className="quantity-controls">
                <button
                  className="quantity-icon"
                  onClick={() => setSelectedQuantity(Math.max(selectedQuantity - 1, 1))}
                  disabled={selectedQuantity === 1}
                >
                  -
                </button>
                <span className="quantity">{selectedQuantity}</span>
                <button
                  className="quantity-icon"
                  onClick={() => setSelectedQuantity(selectedQuantity + 1)}
                >
                  +
                </button>
              </div>
            </div>

            <div className="size-group">
              <label className='size-style'>Select Size:</label>
              <div className="size-options">
                {[7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 13].map((size) => (
                  <button
                    key={size}
                    className={`size-option ${selectedSize === size ? 'selected' : ''}`}
                    onClick={() => handleSizeSelect(size)}
                  >
                    {size}
                  </button>
                ))}
              </div>
            </div>

            <div className="btm">
              <p className="total-price">Total Price: {formattedTotalPrice}</p>
              <button className="add-to-cart-button" onClick={handleAddToCart}>
                Add to Cart
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ProductModal;